import { textChangeRangeNewSpan } from "typescript";

const colors = {
  // NEUTRALS
  black: "#000000",
  blackGray: "#2D2E32",
  white: "#FFFFFF",
  transparent: "#FFFFFF00",
  background: "#FFFFFF",
  gray900: "#101828",
  gray800: "#1D2939",
  gray700: "#344054",
  gray600: "#475467",
  gray500: "#667085",
  gray400: "#98A2B3",
  gray300: "#D0D5DD",
  gray200: "#EAECF0",
  gray100: "#F2F4F7",
  gray50: "#F9FAFB",
  gray25: "#FCFCFD",
  gray1: "#D6DAE4",
  // PRIMARY
  blue800: "#1849A9",
  blue700: "#175CD3",
  blue600a: "#4192BD",
  blue500: "#2E90FA",
  blue600: "#1570EF",
  blue300: "#84CAFF",
  blue100: "#D1E9FF",
  blue200: "#B2DDFF",
  blue50: "#EFF8FF",
  blue25: "#F5FAFF",
  blue1: "#2E3C6F",
  blue2: "#566680",
  blue3: "#2E3C6F",
  blue4: "F2F4FD",
  // SUCCESS
  green700: "#027A48",
  green600: "#039855",
  green300: "#6CE9A6",
  green200: "#ABEFC6",
  green50: "#ECFDF3",
  // STANDAR
  red50: "#FEF3F2",
  red100: "#FEE4E2",
  red500: "#F04438",
  red600: "#D92D20",
  red700: "#B42318",
  // WARNING
  warning50: "#FFFAEB",
  warning500: "#F79009",
  warning600: "#DC6803",
  warning700: "#B54708",
  // ORANGE
  orange700: "#C4320A",
  orange600: "#EC4A0A",
  orange500: "#FB6514",
  orange300: "#FEB273",
  orange200: "#F9DBAF",
  orange50: "#FFF6ED",
  orange25: "#FFFAF5",
  // bluedark
  bluedark950: "#002266",
  bluedark900: "#00359E",
  bluedark800: "#0040C1",
  bluedark700: "#004EEB",
  bluedark600: "#155EEF",
  bluedark500: "#2970FF",
  bluedark400: "#528BFF",
  bluedark300: "#84ADFF",
  bluedark200: "#B2CCFF",
  bluedark100: "#D1E0FF",
  bluedark50: "#EFF4FF",
  bluedark25: "#F5F8FF",
  // ERROR
  error950: "#55160C",
  error900: "#7A271A",
  error800: "#912018",
  error700: "#B42318",
  error600: "#D92D20",
  error500: "#F04438",
  error400: "#F97066",
  error300: "#FDA29B",
  error200: "#FECDCA",
  error100: "#FEE4E2",
  error50: "#FEF3F2",
  error25: "#FFFBFA",
  success600: "#079455",
  primaryAserta: "#F9A11B",

  // NEW DESING
  nBlue1: "#EEF4FB",
  nGray1: "#B0A898",
  nGray2: "#DEE3F0"
} as const;
export type Color = typeof colors[keyof typeof colors];
export default colors
